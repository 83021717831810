<template>
  <Breadcrumb />
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <form class="row g-3 form-model-edit" novalidate>
            <div v-if="errors.length > 0" class="alert alert-warning">
              <ul class="mb-0">
                <li v-for="error in errors" v-bind:key="error">
                  {{ error }}
                </li>
              </ul>
            </div>
            <div v-if="isSuccess" class="alert alert-success">
              {{
                $t(
                  "Messages.SuccessMessage",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </div>
            <div class="col-md-6">
              <label for="Name" class="form-label required"
                >{{
                  $t(
                    "SetCodeModels.Name",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </label>
              <input
                type="text"
                class="form-control"
                id="Name"
                v-model="setModelData.name"
                :autocomplete="this.$isAutoComplete"
                :spellcheck="this.$isTextSpellCheck"
                required
              />
            </div>
            <div class="col-md-6">
              <label for="FormulaName" class="form-label required">{{
                $t(
                  "SetCodeModels.FormulaName",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</label>

              <input
                type="text"
                class="form-control text-uppercase"
                id="FormulaName"
                :value="setModelData.formulaName"
                :autocomplete="this.$isAutoComplete"
                :spellcheck="this.$isTextSpellCheck"
                required
                disabled
              />
            </div>
            <div class="col-md-12">
              <label for="Description" class="form-label required"
                >{{
                  $t(
                    "SetCodeModels.Description",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </label>
              <input
                type="textarea"
                class="form-control"
                id="Description"
                v-model="setModelData.description"
                :autocomplete="this.$isAutoComplete"
                :spellcheck="this.$isTextSpellCheck"
                required
              />
            </div>
            <div class="col-md-12">
              <label for="ModelCode" class="form-label required"
                >{{
                  $t(
                    "SetCodeModels.ModelCode",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </label>
              <v-ace-editor
                v-model:value="setModelData.modelCode"
                lang="csharp"
                theme="monokai"
                @init="editorInit"
                :options="options"
                style="height: 250px"
              />
            </div>
            <div class="col-12">
              <div class="form-check">
                <label for="IsActive" class="form-label">{{
                  $t(
                    "BaseModelFields.IsActive",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="IsActive"
                  v-model="setModelData.isActive"
                />
              </div>
            </div>
            <ActionButtons
              @methodSave="onSubmit('btn-save')"
              :isNewRecord="false"
            />
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import { VAceEditor } from "vue3-ace-editor";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/snippets/javascript";
import "ace-builds/src-noconflict/ext-searchbox";
import ace from "ace-builds";
import workerJsUrl from "file-loader?esModule=false!ace-builds/src-noconflict/worker-javascript.js";
ace.config.setModuleUrl("ace/mode/javascript_worker", workerJsUrl);
export default {
  name: "SetCodeModelEdit",
  components: { VAceEditor },
  data() {
    return {
      editorInit: function (editor) {
        editor.setOptions({
          enableBasicAutocompletion: true,
          enableSnippets: true,
          enableLiveAutocompletion: true,
          autoScrollEditorIntoView: true,
        });
        editor.setShowPrintMargin(false);
      },
      options: {
        autoScrollEditorIntoView: true,
        enableBasicAutocompletion: true,
        enableSnippets: true,
        enableLiveAutocompletion: true,
        useWorker: true,
        wrap: true,
      },
      submitButtonHtml: String.format(
        '<span class="loading-block"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span> {0}</span></span>',
        this.$t(
          "Buttons.PleaseWait",
          {},
          { locale: this.$store.state.activeLang }
        )
      ),
      disabledButtonClasses: ".btn-save",
      errors: [],
      setModelData: {},
    };
  },
  methods: {
    getModel() {
      this.$prodGatewayAxios
        .get(
          String.format(
            "/Brs-SetCodeModelGet?id={0}",
            this.$route.params.modelId
          )
        )
        .then((response) => {
          this.setModelData = response.data;
        })
        .catch(function (error) {
          alert(error);
        });
    },
    onSubmit(buttonName) {
      var form = $(".form-model-edit");
      form.addClass("was-validated");
      if (
        !form[0].checkValidity() ||
        form.find(".ms-invalid:visible").length > 0
      ) {
        return;
      }

      var button = $(String.format(".{0}", buttonName)),
        firstSpan = button.find("span:first"),
        loadingBlock = button.find(".loading-block"),
        disabledButtons = $(this.disabledButtonClasses);

      disabledButtons.prop("disabled", true);
      firstSpan.hide();
      if (loadingBlock.length === 0) {
        firstSpan.after(this.submitButtonHtml);
        loadingBlock = button.find(".loading-block");
      } else {
        loadingBlock.show();
      }

      this.errors = [];
      this.$prodGatewayAxios
        .post("/Brs-SetCodeModelUpdate", { ...this.setModelData })
        .then((r) => {
          var response = r.data;
          if (response.isOk) {
            this.$router.push("/SetCode/Model/List");
          } else {
            firstSpan.show();
            loadingBlock.hide();
            disabledButtons.prop("disabled", false);
            this.errors.push(response.msg);
          }
        })
        .catch((r) => {
          firstSpan.show();
          loadingBlock.hide();
          disabledButtons.prop("disabled", false);
          this.errors.push(r);
        });
    },
  },
  mounted() {
    this.getModel();
  },
};
</script>
